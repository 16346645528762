/**
 * Created by Salma in July/August 2016.
 */

/** @namespace window.siteObj - Set in JavaScript Head */
/** @namespace siteObj.ajaxFacetsAPI */

define(['app', 'siteObj', 'urlReader'], function(app, siteObj, urlReader) {

  var __apiUrl = siteObj.ajaxFacetsAPI;

  /**
   * Ajax Facets.
   * @namespace
   */
  var _facetsMobile = {

    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      _facetsMobile._bindEvents();

      app.publish('url/updateIdentifiers', '', '', '', '|');

      urlReader.constructor = {
        variableQuery: '?',
        categoryValueSeparator: '=',
        categorySeparator: '&',
        segmentSeparator: '|',
      };

      app.subscribe('facets/updatePage', _facetsMobile._updatePageCallback);

      _facetsMobile._populateSelectedObject();
      _facetsMobile._checkSelectedFiltersPanel();
      app.publish('url/init');
    },

    _updatePageCallback: function(action, segment, category, clearPageNumber) {
      if (clearPageNumber) {
        _facetsMobile._clearPageNumber = true;
      }

      var location = urlReader.deconstructGet(action, segment, category);
      _facetsMobile._getData(_facetsMobile._updateLocation(location));
    },

    _editRefinementText: app.utils.getProperty('facetsMobileEditRefinementText', 'facetsMobile'),
    _refineText: app.utils.getProperty('facetsMobileRefineText', 'facetsMobile'),

    _selected: {
      facets: [],
      counter: {}
    },

    _apiKey: 'facetFilters',

    _pageNumberKey: 'pageNumber',

    _sortOrderKey: 'sortOrder',

    _cachedPath: window.location.href,

    _currentListName: null,

    _returnToProductList: true,

    _clearPageNumber: false,

    _productListStringTemplate: document.querySelector('[data-productlist]')
      ? document.querySelector('[data-productlist]').getAttribute('data-productlist-st') : null,

    _facetSelectors: {
      js: {
        facetsWrapper: 'js-facets-wrapper',
        clear: 'js-facets-facet-clear',
        clearSet: 'js-facets-set-clear',
        clearAll: 'js-facets-clear-all',
        clearChildLevel1: 'js-facets-facet-clear-child-level1',
        clearChildLevel2: 'js-facets-facet-clear-child-level2',
        openList: 'js-facets-open-list',
        closeList: 'js-facets-close-list',
        label: 'js-facets-checkbox-label',
        checkbox: 'js-facets-checkbox',
        checkboxChecked: 'js-facets-checkbox-checked',
        listItem: 'js-facets-list-item',
        saveAndView: 'js-facets-save-and-view',
        saveAndViewButton: 'js-facets-save-and-view-button',
        refineButton: 'js-facets-refine-button',
        refineButtonText: 'js-facets-refine-text',
        selectedFiltersPanel: 'js-facets-selected-filters-panel',
        selectedFiltersPanelListItem: 'js-facets-selected-filters-panel-list-item',
        selectedFiltersPanelList: 'js-facets-selected-filters-panel-list',
        selectedFiltersPanelListHolder: 'js-facets-selected-filters-panel-list-holder',
        filtersPanelVeil: 'js-facets-filters-panel-veil',
        listPanelsList: 'js-facets-set-lists',
        facetSetList: 'js-facets-set-list',
        facetSetLists: 'js-facets-set-lists',
        facetSetListItem: 'js-facet-set-list-item',
        stickyHeader: 'js-sticky-header'
      },
      css: {
        show: 'facets-show',
        showSaveAndView: 'facets_saveAndView-show',
        openedList: 'facets_facetSetListHolder-show',
        checked: 'facets_facetSetListInnerItemCheckbox-checked',
        labelChecked: 'facets_facetSetListInnerItemLabel-checked',
        refineButtonFiltersApplied: 'facets_buttonRefine-filtersApplied',
        showLoadingOverlay: 'facets_loadingOverlay-show',
        listPanelListBorderTop: 'facets_facetSetList-borderTop',
        selectedFiltersPanel: 'facets_selectedFiltersPanel',
        filtersPanelVeil: 'facets_filtersPanelVeil',
        filtersPanelVeilShow: 'facets_filtersPanelVeil-show',
        selectedFiltersPanelHolderShow: 'facets_selectedFiltersPanelListHolder-show',
        selectedFiltersPanelBorderBottom: 'facets_selectedFiltersPanel-borderBottom',
        facetSetListShow: 'facets_facetSetList-show',
        bodyNoScroll: 'facets_noScroll',
        headerNoSticky: 'facets_headerNoSticky',
        facetSetListItemHide: 'facets_facetSetListItem-hide',
        facetSetListItemOpen: 'facets_facetSetListItem-open'
      },
    },

    _htmlElements: {
      facetsControls: document.querySelector('.js-facets-controls'),
      facetsWrapper: document.querySelector('.js-facets-wrapper'),
      productsWrapper: document.querySelector('.js-products-wrapper'),
      loadingOverlay: document.querySelector('.js-facets-loading-overlay'),
      mainWrapper: document.querySelector('[data-component="facetsMobile"]'),
      noResults: document.querySelector('.js-facets-no-results'),
      saveAndView: document.querySelector('.js-facets-save-and-view'),
      refineButton: document.querySelector('.js-facets-refine-button'),
      refineButtonText: document.querySelector('.js-facets-refine-text'),
      selectedFiltersPanel: document.querySelector('.js-facets-selected-filters-panel'),
      selectedFiltersPanelList: document.querySelector('.js-facets-selected-filters-panel-list'),
      selectedFiltersPanelListHolder: document.querySelector('.js-facets-selected-filters-panel-list-holder'),
      listPanelsList: document.querySelector('.js-facets-set-lists'),
      filtersPanelVeil: document.querySelector('.js-facets-filters-panel-veil'),
      stickyHeader: document.querySelector('.js-sticky-header'),
      listTitleWrapper: document.querySelector('.js-listTitle-wrapper')
    },

    /**.
     * @param element
     * @returns object
     * @private
     */
    _getFacetData: function(element) {
      var facetData = {
        facet: '',
        facetSetDisplayName: '',
        facetName: '',
        facetValue: '',
        facetNameValuePair: '',
        facetSetListToClear: '',
      };

      facetData.facet = app.element.getAttribute('data-facet', element);
      facetData.facetSetDisplayName = app.element.getAttribute('data-facet-set-display-name', element);
      facetData.facetName = app.element.getAttribute('data-facet-name', element);
      facetData.facetValue = app.element.getAttribute('data-facet-value', element);
      facetData.facetNameValuePair = app.element.getAttribute('data-facet-name-value', element);
      facetData.facetSetListToClear = app.element.getAttribute('data-facet-set-list-to-clear', element);

      return facetData;
    },
    _filterUnique: function(arr) {
      var unique = [];
      for (var i = 0; i < arr.length; i++) {
        if (unique.indexOf(arr[i]) === -1) {
          unique.push(arr[i]);
        }
      }
      return unique;
    },

    /**
     * Remove duplicate facets in the url.
     * @param url
     * @returns uniqueUrl
     * @private
     */
    _removeDuplicateFacetsInUrl: function(url) {
      var clonedSelectedFacetsArray = _facetsMobile._selected.facets,
        uniqueUrl;

      _facetsMobile._initUrl = window.location.href || '';

      if (url) {
        var facetListArr = url.split(_facetsMobile._apiKey + '=');
        clonedSelectedFacetsArray = facetListArr && facetListArr[1] ? facetListArr[1].split(urlReader.constructor.segmentSeparator) : [];

        if (_facetsMobile._selected.facets.length) {
          var uniqueFacetFiltersStr = _facetsMobile._filterUnique(clonedSelectedFacetsArray).join(urlReader.constructor.segmentSeparator);
          uniqueUrl = facetListArr[0] + _facetsMobile._apiKey + '=' + uniqueFacetFiltersStr;
        } else {
          uniqueUrl = facetListArr[0];
        }
        return uniqueUrl;
      }
    },

    /**
     * Add events to facet container.
     * @private
     */
    _bindEvents: function() {
      _facetsMobile._addClickEvent('.' + _facetsMobile._facetSelectors.js.refineButton, _facetsMobile._showFacetLists);
      _facetsMobile._addClickEvent('.' + _facetsMobile._facetSelectors.js.facetsWrapper, _facetsMobile._facetWrapperClick);
      _facetsMobile._addClickEvent('.' + _facetsMobile._facetSelectors.js.saveAndView, _facetsMobile._facetWrapperClick);
    },

    /**
     * Add click events.
     * @param cssIdentifier
     * @param callback
     * @private
     */
    _addClickEvent: function(cssIdentifier, callback) {
      var domElem = (cssIdentifier) ? app.select(cssIdentifier) : 0;
      if (domElem && typeof (callback) === 'function') {
        domElem.off('click', callback).on('click', callback);
      }
    },

    /**
     * Add facet filters to the _facetsMobile.selected object.
     * This function runs on the initial page load and after an Ajax _getData().
     * @private
     */
    _populateSelectedObject: function() {
      var facetData,
        checkedCheckboxes = document.querySelectorAll('.' + _facetsMobile._facetSelectors.js.checkboxChecked);

      if (checkedCheckboxes.length) {
        _facetsMobile._resetSelectedObject();
        for (var i = 0, length = checkedCheckboxes.length; i < length; i++) {
          var thisCheckbox = checkedCheckboxes[i];
          facetData = _facetsMobile._getFacetData(thisCheckbox);
          _facetsMobile._pushIntoSelectedFacets(facetData);
          _facetsMobile._updateSelectedFacetsCounter('add', facetData);
        }
      } else {
        _facetsMobile._resetSelectedObject();
      }
    },

    /**
     * Update the _facetsMobile._selected.facets array
     * @param facetData
     * @private
     */
    _pushIntoSelectedFacets: function(facetData) {
      if (_facetsMobile._selected.facets.length) {
        for (var j = 0; j < _facetsMobile._selected.facets.length; j++) {
          if (facetData.facetNameValuePair !== _facetsMobile._selected.facets[j]) {
            _facetsMobile._selected.facets.push(facetData.facetNameValuePair);
            break;
          }
        }
      } else {
        _facetsMobile._selected.facets.push(facetData.facetNameValuePair);
      }
    },

    /**
     * Updates the _facetsMobile._selected.counter object.
     * @param action | string | 'add'/'remove'
     * @param facetData | object
     * @private
     */
    _updateSelectedFacetsCounter: function(action, facetData) {
      if (facetData) {
        var currentValue = _facetsMobile._selected.counter[facetData.facet] || 0;
        var newValue = (action === 'add') ? currentValue + 1 : currentValue - 1;
        _facetsMobile._selected.counter[facetData.facet] = (newValue < 0) ? 0 : newValue;
      }
    },

    /**
     * Clear the _facetsMobile._selected object facets and counter.
     * @private
     */
    _resetSelectedObject: function() {
      _facetsMobile._selected.facets = [];
      _facetsMobile._selected.counter = {};
    },

    /**
     * Check to see if we need to adjust the UI of the selected filters panel.
     */
    _checkSelectedFiltersPanel: function() {
      var selectedFiltersPanelListHolder = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelListHolder),
        selectedFiltersPanel = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanel);

      if (_facetsMobile._selected.facets.length && selectedFiltersPanelListHolder) {
        app.element.addClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelHolderShow, selectedFiltersPanelListHolder);
      } else if (selectedFiltersPanelListHolder) {
        app.element.removeClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelHolderShow, selectedFiltersPanelListHolder);
        app.element.removeClass(_facetsMobile._facetSelectors.css.listPanelListBorderTop, _facetsMobile._htmlElements.listPanelsList);
      }

      if (selectedFiltersPanel) {
        var computedMaxHeight = _facetsMobile._getElementComputedMaxHeight(selectedFiltersPanel),
          actualHeight = _facetsMobile._getElementOffsetHeight(selectedFiltersPanel);
        _facetsMobile._adjustSelectedFiltersPanelInterface(selectedFiltersPanel, computedMaxHeight, actualHeight);
      }
    },

    _getElementComputedMaxHeight: function(element) {
      return parseInt(window.getComputedStyle(element).getPropertyValue('max-height'), 0);
    },

    _getElementOffsetHeight: function(element) {
      return element.offsetHeight;
    },

    _adjustSelectedFiltersPanelInterface: function(selectedFiltersPanel, computedMaxHeight, actualHeight) {
      var scrollVeil = document.querySelector('.' + _facetsMobile._facetSelectors.js.filtersPanelVeil);

      if (actualHeight >= computedMaxHeight) {
        app.element.addClass(_facetsMobile._facetSelectors.css.filtersPanelVeilShow, scrollVeil);
        app.element.addClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelBorderBottom, selectedFiltersPanel);
      } else {
        app.element.removeClass(_facetsMobile._facetSelectors.css.filtersPanelVeilShow, scrollVeil);
        app.element.removeClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelBorderBottom, selectedFiltersPanel);
      }
    },

    /**
     * Alter the state of the 'refine' filters button depending on whether the user has any
     * facet filters selected.
     * @private
     */
    _checkRefineButton: function() {
      var refineButton = document.querySelector('.js-facets-refine-button'),
        refineButtonText = document.querySelector('.js-facets-refine-text');

      if (refineButtonText && refineButton) {
        if (_facetsMobile._selected.facets.length) {
          _facetsMobile._setRefineButtonText(refineButtonText, _facetsMobile._editRefinementText);
          app.element.addClass(_facetsMobile._facetSelectors.css.refineButtonFiltersApplied, refineButton);
        } else {
          _facetsMobile._setRefineButtonText(refineButtonText, _facetsMobile._refineText);
          app.element.removeClass(_facetsMobile._facetSelectors.css.refineButtonFiltersApplied, refineButton);
        }
      }
    },

    _setRefineButtonText: function(buttonTextElement, text) {
      buttonTextElement.textContent = text;
    },

    _removeSelectedFacetClasses: function(element) {
      app.element.removeClass(_facetsMobile._facetSelectors.js.checkboxChecked, element);
      app.element.removeClass(_facetsMobile._facetSelectors.css.checked, element);
    },

    _addSelectedFacetClasses: function(element) {
      app.element.addClass(_facetsMobile._facetSelectors.js.checkboxChecked, element);
      app.element.addClass(_facetsMobile._facetSelectors.css.checked, element);
    },

    /**
     * Retrieve click event and set target
     * @param event
     * @private
     */
    _facetWrapperClick: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;
      if (!target) {
        return;
      }

      _facetsMobile._filterClickableElements(target, event);
    },

    /**
     * Determine click behaviour for facet panel
     * @param target
     * @param event
     * @private
     */
    _filterClickableElements: function(target, event) {
      var facetData;

      // open a single facet list
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.openList, target)) {
        _facetsMobile._currentListName = app.element.getAttribute('data-open-list', target);
        _facetsMobile._openSingleFacetList();
        return;
      }

      if (app.element.hasClass(_facetsMobile._facetSelectors.js.listItem, target) || app.element.hasClass(_facetsMobile._facetSelectors.js.label, target)) {
        return;
      } else if (app.element.hasClass(_facetsMobile._facetSelectors.js.checkbox, target)) {
        facetData = _facetsMobile._getFacetData(target);
        _facetsMobile._facetClick(target, facetData);
        return;
      }

      if (app.element.hasClass(_facetsMobile._facetSelectors.js.closeList, target)) {
        _facetsMobile._currentListName = app.element.getAttribute('data-close-list', target);
        _facetsMobile._showFacetLists();
        _facetsMobile._returnToProductList = false;
        _facetsMobile._updatePage();
        return;
      }

      // Clear single facet - clicking on a level 1 child of the js-facets-facet-clear item
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.clearChildLevel1, target)) {
        target = target.parentNode;
        facetData = _facetsMobile._getFacetData(target);

        if (facetData.facet !== '' && facetData.facetNameValuePair !== '') {
          _facetsMobile._returnToProductList = false;
          _facetsMobile._removeFacetFilter(facetData);
          _facetsMobile._updatePage();
          return;
        }
      }

      // Clear single facet - clicking on a level 2 child of the js-facets-facet-clear item
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.clearChildLevel2, target)) {
        target = target.parentNode.parentNode;
        facetData = _facetsMobile._getFacetData(target);

        if (facetData.facet !== '' && facetData.facetNameValuePair !== '') {
          _facetsMobile._returnToProductList = false;
          _facetsMobile._removeFacetFilter(facetData);
          _facetsMobile._updatePage();
          return;
        }
      }

      // Clear single facet - clicking directly on js-facets-facet-clear item
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.clear, target)) {
        facetData = _facetsMobile._getFacetData(target);

        if (facetData.facet !== '' && facetData.facetNameValuePair !== '') {
          _facetsMobile._returnToProductList = false;
          _facetsMobile._removeFacetFilter(facetData);

          _facetsMobile._updatePage();
          return;
        }
      }

      // Clear a facet set from the inner facet list
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.clearSet, target)) {
        facetData = _facetsMobile._getFacetData(target);

        if (facetData.facetSetListToClear !== '') {
          _facetsMobile._clearFacetSet(facetData.facetSetListToClear);
          return;
        }
      }

      // Save & View button
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.saveAndViewButton, target)) {
        _facetsMobile._updatePage();
        return;
      }

      // Clear all button
      if (app.element.hasClass(_facetsMobile._facetSelectors.js.clearAll, target)) {
        _facetsMobile._returnToProductList = false;
        _facetsMobile._clearAllFacets();
        return;
      }

      (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
    },

    _addClassToStickyHeader: function() {
      app.element.addClass(_facetsMobile._facetSelectors.css.headerNoSticky, _facetsMobile._htmlElements.stickyHeader);
    },

    _removeClassFromStickyHeader: function() {
      app.element.removeClass(_facetsMobile._facetSelectors.css.headerNoSticky, _facetsMobile._htmlElements.stickyHeader);
    },

    /**
     * Show all facet lists
     * @private
     */
    _showFacetLists: function() {
      var allLists = document.querySelectorAll('.' + _facetsMobile._facetSelectors.js.facetSetListItem);

      app.element.addClass(_facetsMobile._facetSelectors.css.show, _facetsMobile._htmlElements.facetsControls);
      app.element.addClass(_facetsMobile._facetSelectors.css.showSaveAndView, _facetsMobile._htmlElements.saveAndView);

      if (_facetsMobile._htmlElements.stickyHeader !== null) {
        _facetsMobile._addClassToStickyHeader();
      }

      // this class is added to prevent the body scroll behind the facets
      // a setTimeout() is used so the page doesn't jump as soon as the user clicks the 'refine' button
      setTimeout(function() {
        app.element.addClass(_facetsMobile._facetSelectors.css.bodyNoScroll, document.querySelector('body'));
      }, 500);

      _facetsMobile._checkSelectedFiltersPanel();

      if (_facetsMobile._currentListName !== null) {
        var listToClose = document.querySelector('[data-list="' + _facetsMobile._currentListName + '"]');
        app.element.removeClass(_facetsMobile._facetSelectors.css.openedList, listToClose);
        app.element.removeClass(_facetsMobile._facetSelectors.css.facetSetListShow, document.querySelector('.' + _facetsMobile._facetSelectors.js.facetSetLists));
        app.element.addClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelHolderShow, document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelListHolder));

        for (var i = 0, length = allLists.length; i < length; i++) {
          var thisList = allLists[i];
          app.element.removeClass(_facetsMobile._facetSelectors.css.facetSetListItemHide, thisList);
          app.element.removeClass(_facetsMobile._facetSelectors.css.facetSetListItemOpen, thisList);
        }
      }

      _facetsMobile._currentListName = null;
    },

    /**
     * Hide all facet lists
     * @private
     */
    _hideFacetLists: function() {
      app.element.removeClass(_facetsMobile._facetSelectors.css.show, _facetsMobile._htmlElements.facetsControls);
      app.element.removeClass(_facetsMobile._facetSelectors.css.showSaveAndView, _facetsMobile._htmlElements.saveAndView);
      app.element.removeClass(_facetsMobile._facetSelectors.css.bodyNoScroll, document.querySelector('body'));

      if (_facetsMobile._htmlElements.stickyHeader !== null) {
        _facetsMobile._removeClassFromStickyHeader();
      }
    },

    /**
     * Get url
     * @returns {{api: {url: string, urlVariables: string}}}
     * @private
     */
    _getLocationObject: function() {
      var urlSplitter,
        cachedPath = _facetsMobile._cachedPath;

      // Change the url to a normal search if the user has performed a 'suggested search'
      if (cachedPath.indexOf(urlReader.constructor.variableQuery + 'autocomplete=searchsuggestion') > -1) {
        cachedPath = cachedPath.replace('autocomplete=searchsuggestion&', '');
      }

      urlSplitter = (cachedPath.indexOf(urlReader.constructor.variableQuery + 'search=') > -1) ? urlReader.constructor.categorySeparator : urlReader.constructor.variableQuery;

      return {
        api: {
          url: cachedPath.split(urlSplitter)[0],
          urlVariables: cachedPath.split(urlSplitter)[1] || '',
        }
      };
    },

    /**
     * Remove the existing page number from the URL when applying new filters, if it exists.
     * @param url
     * @private
     */
    _removePageNumberFromUrl: function(url) {
      if (url.indexOf(_facetsMobile._pageNumberKey) === -1) {
        return url;
      } else {
        var regex = new RegExp('(' + _facetsMobile._pageNumberKey + '=[0-9^\&]+)');
        return url.replace(regex, '');
      }
    },

    /**
     * Update location
     * @param location
     * @private
     */
    _updateLocation: function(location) {
      var getDataUrl = location.window.url;
      var queryParam = '?';

      if (location && location.api && location.api.url) {
        getDataUrl = _facetsMobile._removeDuplicateFacetsInUrl(__apiUrl + location.api.urlVariables);
        var windowUrl = _facetsMobile._removeDuplicateFacetsInUrl(location.window.url);

        if (_facetsMobile._clearPageNumber) {
          getDataUrl = _facetsMobile._removePageNumberFromUrl(getDataUrl);
          windowUrl = _facetsMobile._removePageNumberFromUrl(windowUrl);
        }

        // remove the trailing ? or & if it exists from the url and selected facets are empty
        // this can happen if all facets have been cleared
        if (!_facetsMobile._selected.facets.length) {
          var regex = new RegExp('[\?&]$');
          windowUrl = windowUrl.replace(regex, '');
        }

        app.publish('url/updatePath', windowUrl);
      }

      if (_facetsMobile._selected.facets.length || getDataUrl.indexOf(_facetsMobile._sortOrderKey) > -1 || getDataUrl.indexOf(_facetsMobile._pageNumberKey) > -1) {
        queryParam = '&';
      }
      if (getDataUrl.indexOf('?') > -1) {
        queryParam = '&';
      }

      var stringTemplateRequest = _facetsMobile._productListStringTemplate ? queryParam + 'productListStringTemplate=' + _facetsMobile._productListStringTemplate : '';

      return getDataUrl + stringTemplateRequest;
    },

    /**
     * Update page after clicking 'Save & View' or clear all facets
     * @private
     */
    _updatePage: function() {
      var location = _facetsMobile._getLocationObject(),
        action = _facetsMobile._getUpdateAction(),
        url;

      if (_facetsMobile._selected.facets.length) {
        for (var i = 0; i < _facetsMobile._selected.facets.length; i++) {
          url = location.api.url || '';
          location = urlReader.deconstructGet(action, _facetsMobile._selected.facets[i], _facetsMobile._apiKey, url);
        }
      } else {
        url = location.api.url || '';
        location = urlReader.deconstructGet(action, [], _facetsMobile._apiKey, url);
      }

      _facetsMobile._getData(_facetsMobile._updateLocation(location));
    },

    _getUpdateAction: function() {
      if (_facetsMobile._selected.facets.length) {
        return 'add';
      } else {
        return 'remove';
      }
    },

    /**
     * Check / uncheck a facet.
     * @param target - an input[type=checkbox] element
     * @param facetData
     * @private
     */
    _facetClick: function(target, facetData) {
      _facetsMobile._cachedPath = window.location.href;

      if (app.element.hasClass(_facetsMobile._facetSelectors.js.checkboxChecked, target)) {
        _facetsMobile._removeFacetFilter(facetData);
      } else {
        _facetsMobile._addFacetFilter(target);
      }

      _facetsMobile._tracking(facetData);
    },

    /**
     * Toggle Categories, activate, de-activate
     * @private
     */
    _openSingleFacetList: function() {
      var listToOpen = document.querySelector('[data-list="' + _facetsMobile._currentListName + '"]'),
        filtersPanelVeil = document.querySelector('.' + _facetsMobile._facetSelectors.js.filtersPanelVeil),
        otherLists = document.querySelectorAll('.' + _facetsMobile._facetSelectors.js.facetSetListItem);

      if (listToOpen) {
        app.element.addClass(_facetsMobile._facetSelectors.css.openedList, listToOpen);
        app.element.addClass(_facetsMobile._facetSelectors.css.facetSetListItemOpen, document.querySelector('.' + _facetsMobile._facetSelectors.js.facetSetListItem + '[data-open-list="' + _facetsMobile._currentListName + '"]'));
        app.element.addClass(_facetsMobile._facetSelectors.css.facetSetListShow, document.querySelector('.' + _facetsMobile._facetSelectors.js.facetSetLists));
        app.element.removeClass(_facetsMobile._facetSelectors.css.selectedFiltersPanelHolderShow, document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelListHolder));

        if (filtersPanelVeil) {
          app.element.removeClass(_facetsMobile._facetSelectors.css.filtersPanelVeilShow, filtersPanelVeil);
        }

        for (var i = 0; i < otherLists.length; i++) {
          if (app.element.getAttribute('data-open-list', otherLists[i]) !== _facetsMobile._currentListName) {
            app.element.addClass(_facetsMobile._facetSelectors.css.facetSetListItemHide, otherLists[i]);
          }
        }
      }
    },

    /**
     * This function runs when the user adds a facet filter by clicking a checkbox.
     * This function updates the necessary CSS and runs functions to update appropriate UI elements.
     * @param checkbox | element
     * @private
     */
    _addFacetFilter: function(checkbox) {
      var facetData = _facetsMobile._getFacetData(checkbox);
      _facetsMobile._addSelectedFacetClasses(checkbox);
      _facetsMobile._pushIntoSelectedFacets(facetData);
      _facetsMobile._updateSelectedFacetsCounter('add', facetData);
      _facetsMobile._clearPageNumber = true;
    },

    /**
     * This function runs when the user removes a facet filter by clicking a checkbox.
     * This function updates the necessary CSS and runs functions to update appropriate UI elements.
     * @param facetData
     * @private
     */
    _removeFacetFilter: function(facetData) {
      var checkbox = document.querySelector('.' + _facetsMobile._facetSelectors.js.checkbox + '[data-facet-name-value="' + facetData.facetNameValuePair + '"]');

      if (checkbox) {
        _facetsMobile._removeSelectedFacetClasses(checkbox);
      }

      if (_facetsMobile._selected.facets.length) {
        for (var k = 0; k < _facetsMobile._selected.facets.length; k++) {
          if (facetData.facetNameValuePair === _facetsMobile._selected.facets[k]) {
            _facetsMobile._selected.facets.splice(k, 1);
            _facetsMobile._updateSelectedFacetsCounter('remove', facetData);
            _facetsMobile._removeItemFromSelectedFiltersPanelList(facetData);
          }
        }
      }

      _facetsMobile._clearPageNumber = true;
    },

    /**
     * This function runs when the user removes a facet filter by clicking a checkbox.
     * This function removes HTML form the selected filters panel list to update the full list
     * of selected filters before the DOM is reloaded via Ajax.
     * @param facetData
     * @private
     */
    _removeItemFromSelectedFiltersPanelList: function(facetData) {
      var listItemToRemove = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelListItem + '[data-facet-name-value="' + facetData.facetNameValuePair + '"]');
      _facetsMobile._htmlElements.selectedFiltersPanelList = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelList);

      if (listItemToRemove !== null) {
        _facetsMobile._htmlElements.selectedFiltersPanelList.removeChild(listItemToRemove);

        // Even though we query these elements on init(), we need to bind them again in case the user has updated the page via Ajax
        // and after we removeChild.
        _facetsMobile._htmlElements.selectedFiltersPanelList = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelList);
        _facetsMobile._htmlElements.selectedFiltersPanelListHolder = document.querySelector('.' + _facetsMobile._facetSelectors.js.selectedFiltersPanelListHolder);

        if (!_facetsMobile._htmlElements.selectedFiltersPanelList.children.length) {
          _facetsMobile._htmlElements.selectedFiltersPanelListHolder.innerHTML = '';
          _facetsMobile._htmlElements.selectedFiltersPanelList = null;
          // Adjust the appearance of the facets_facetSetList
          app.element.removeClass(_facetsMobile._facetSelectors.css.listPanelListBorderTop, _facetsMobile._htmlElements.listPanelsList);
        }
      }
    },

    /**
     * Clears a single facet set
     * @private
     */
    _clearFacetSet: function(facetListToClear) {
      var checkedCheckboxes = document.querySelectorAll('.' + _facetsMobile._facetSelectors.js.facetSetList + '-' + facetListToClear + ' .' + _facetsMobile._facetSelectors.js.checkboxChecked),
        facetData,
        thisCheckbox;

      for (var i = 0, length = checkedCheckboxes.length; i < length; i++) {
        thisCheckbox = checkedCheckboxes[i];
        facetData = _facetsMobile._getFacetData(thisCheckbox);
        _facetsMobile._removeFacetFilter(facetData);
        _facetsMobile._updateSelectedFacetsCounter('remove', facetData);
      }

      _facetsMobile._populateSelectedObject();
    },

    /**
     * Gets the response JSON object and show it in appropriate DIV elements in the DOM
     * @param response
     * @private
     */
    _decoupleResponse: function(response) {
      response = response || {};

      if (!response.productList) {
        response.pagination = ' ';
        app.element.removeClass('facets_noProductsFound-hide', _facetsMobile._htmlElements.noResults);
      } else {
        app.element.addClass('facets_noProductsFound-hide', _facetsMobile._htmlElements.noResults);
      }

      if (_facetsMobile._htmlElements.listTitleWrapper !== null) {
        _facetsMobile._htmlElements.listTitleWrapper.innerHTML = response.listTitle;
      }

      _facetsMobile._htmlElements.productsWrapper.innerHTML = response.productList;

      if (response.facetSetList) {
        _facetsMobile._htmlElements.facetsWrapper.innerHTML = response.facetSetList;
      }

      if (response.pagination) {
        app.publish('pageControls/update', response.pagination);
      }
      return true;
    },

    /**
     *
     * Clear all facet filters.
     * @private
     */
    _clearAllFacets: function() {
      var location = {
        api: {
          url: window.location.href
        }
      };

      var selectedFacets = document.querySelectorAll('.' + _facetsMobile._facetSelectors.js.checkboxChecked),
        thisSelectedFacet;

      for (var i = 0, length = selectedFacets.length; i < length; i++) {
        thisSelectedFacet = selectedFacets[i];
        _facetsMobile._removeSelectedFacetClasses(thisSelectedFacet);
        location = urlReader.deconstructGet('remove', thisSelectedFacet.getAttribute('data-facet-name-value'), _facetsMobile._apiKey, location.api.url);
        _facetsMobile._location = location;
      }

      _facetsMobile._resetSelectedObject();
      _facetsMobile._updatePage();
    },

    /**
     * Loading request non blocking
     * @param showOverlay
     * @private
     */
    _loading: function(showOverlay) {
      app.element.scrollTo(_facetsMobile._htmlElements.mainWrapper, 250);
      if (showOverlay) {
        app.element.addClass(_facetsMobile._facetSelectors.css.showLoadingOverlay, _facetsMobile._htmlElements.loadingOverlay);
      } else {
        app.element.removeClass(_facetsMobile._facetSelectors.css.showLoadingOverlay, _facetsMobile._htmlElements.loadingOverlay);
      }
    },

    _ajaxSuccess: function(response) {
      var productsLoaded = _facetsMobile._decoupleResponse(response);
      _facetsMobile._resetSelectedObject();

      if (productsLoaded) {
        if (_facetsMobile._returnToProductList) {
          _facetsMobile._hideFacetLists();
        }
        _facetsMobile._populateSelectedObject();
        _facetsMobile._updateSelectedFacetsCounter();
        _facetsMobile._checkSelectedFiltersPanel();
        _facetsMobile._checkRefineButton();
        _facetsMobile._loading(false);
        _facetsMobile._returnToProductList = true;
        _facetsMobile._clearPageNumber = false;
        app.publish('pageControls/updateCache', '1');

        //Provide productQuickbuy hook after AJAX success.
        app.publish('productQuickbuy/reinitialise');
        app.publish('addedToBasketModal/reinitialise');
      }

      app.publish('pageControls/bind');
    },

    /**
     * Fire asynchronous call for new page data.
     * @param url
     * @private
     */
    _getData: function(url) {
      _facetsMobile._loading(true);

      app.ajax.get({
        url: url,
        dataType: 'JSON',
        error: _facetsMobile._error,
        success: _facetsMobile._ajaxSuccess
      });
    },

    /**
     * Facet error handler
     * @param error
     * @param why
     * @param method
     * @private
     */
    _error: function(error, why, method) {
      _facetsMobile._loading(true);

      try {
        if (method) {
          console.warn(error, why, method);
        } else {
          console.warn(error, error.message, 'getData');
        }
      } catch (e) {}
    },

    /**
     * Tracking
     * @param facetData
     * @private
     */
    _tracking: function(facetData) {
      app.publish('tracking/record', 'Facets', 'click through',
        _facetsMobile._getListPageTitle()
        + ' - ' +
        facetData.facet
        + ' - ' +
        decodeURIComponent(facetData.facetValue)
      );
    },

    _getListPageTitle: function() {
      return document.querySelector('[data-list-page-title]').innerHTML;
    },
  };

  // Execute module constructor
  _facetsMobile._init();

  // Unit testing access
  return _facetsMobile;
});
